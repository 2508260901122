import React, {useEffect, useContext}  from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Container,
  List,
  ListItem,
  ListItemIcon
} from '@mui/material';
import Page from '../../../components/Page';
import { makeStyles } from '@mui/styles';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { colors } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckOutContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh !important',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.white,
  },
  order: {
    backgroundColor: '#F7F8FA',
    padding: theme.spacing(1,3),
    borderBottom: '1px solid #EDEEEE'
  },
  total: {
    display:'flex',
    padding: theme.spacing(2,3),
    backgroundColor: '#F7F8FA',
    justifyContent: 'space-between'
  },
  orderDetails: {
    padding: theme.spacing(2,3),
    border: '1px solid #EDEEEE'
  }
}));

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: 'increment',
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? colors.grey[300] : colors.grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
`,
);

const StyledInput = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === 'dark' ? colors.grey[300] : colors.grey[900]};
  background: ${theme.palette.mode === 'dark' ? colors.grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? colors.grey[700] : colors.grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${colors.blue[400]};
  }

  &:focus {
    border-color: ${colors.blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? colors.blue[700] : colors.blue[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === 'dark' ? colors.grey[800] : colors.grey[200]};
  background: ${theme.palette.mode === 'dark' ? colors.grey[900] : colors.grey[50]};
  color: ${theme.palette.mode === 'dark' ? colors.grey[200] : colors.grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? colors.blue[700] : colors.blue[500]};
    border-color: ${theme.palette.mode === 'dark' ? colors.blue[500] : colors.blue[400]};
    color: ${colors.grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);

const Plan = () => {
  const classes = useStyles();
  const checkOutContext = useContext(CheckOutContext);
  const { detailsList, plans, individuals, teams, handleTabChange, plan, setPlan } = checkOutContext;
  const [dropdownValue, setDropdownValue] = React.useState(plan);
  const [businessView, setBusinessView] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [fullAccessCount, setFullAccessCount] = React.useState(plan.fullAccessCount);
  const [analystsCount, setAnalystsCount]= React.useState(plan.analystsCount);
  const [list, setList]= React.useState([]);

  const handleDropdownChange = (event, newValue) => {
    setDropdownValue(newValue);
    setPlan({...plan, 'plan': newValue.plan, 'title':newValue.title});
  };
  
  const options = plans.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      plan: /[T]/.test(firstLetter) ? 'Business plans (multiple users)' : 'Personal plans (single user)',
      ...option,
    };
  });

  const cost = (val) => {
    if(!!val) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return 0;
  }

  const getTotal = () => {
    if(!!businessView && !!fullAccessCount && analystsCount === 0) {
      return (fullAccessCount*values.price*12)
    } 
    else if(!!businessView && !!analystsCount && analystsCount > 1) {
      return (fullAccessCount*values.price*12 + analystsCount*values.analystsPrice*12)
    } 
    else if(!businessView) {
      return (values.price*12)
    }
  }

  useEffect(() => {
    if(dropdownValue && dropdownValue.plan === 'Business plans (multiple users)') {
      setBusinessView(true)
      let l = teams.find(t => t.title.split(' ')[1] === dropdownValue.title.split(' ')[1]).list.slice(0,5)
      setList(l);
    } else {
      setBusinessView(false)
      let l = individuals.find(t => t.title.split(' ')[0] === dropdownValue.title.split(' ')[0]).list.slice(0,5)
      setList(l);
    }
    if(dropdownValue && dropdownValue.title) {
      let d = detailsList.find((aa) => aa.title === dropdownValue.title)
      setValues(d);
    }
  }, [dropdownValue, detailsList, individuals, teams]);

  return (
    <Page
      className={classes.root}
      title={'Crux CX - Contact Us'}
    >
      <Container maxWidth="xl" >
        <Grid container display='flex' flexDirection= 'row' justifyContent= 'center' alignContent= 'center' spacing={4}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs= {12}>
                  <Typography gutterBottom variant='h3'>Plan details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h5'>Plan</Typography>
                  <Autocomplete
                    size='small'
                    id="grouped-demo"
                    name='plan'
                    value={dropdownValue}
                    onChange={handleDropdownChange}
                    options={options.sort((a, b) => -b.plan.localeCompare(a.plan))}
                    groupBy={(option) => option.plan}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                {!!dropdownValue && dropdownValue.title && <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>Billing</Typography>
                  <Typography variant='h6'>{values.billingDetails}</Typography>
                </Grid>}
                {!!businessView && <Grid item mt={2} xs= {12}>
                  <Typography gutterBottom variant='h3'>Users</Typography>
                </Grid>}
                {!!businessView && <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant='subtitle2'>Full Access</Typography>
                      <Typography gutterBottom variant='body2'>{values.fullAccess.desc}</Typography>
                      <Typography gutterBottom variant='body2'>{values.fullAccess.unitDesc}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput onChange={(event, newValue) => { setPlan({...plan, 'fullAccessCount': newValue}); setFullAccessCount(newValue)}} defaultValue={values.fullAccess.minimum} value={fullAccessCount} aria-label="Quantity Input" min={values.fullAccess.minimum} max={99} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant='subtitle2'>Analysts</Typography>
                      <Typography gutterBottom variant='body2'>{values.analysts.desc}</Typography>
                      <Typography gutterBottom variant='body2'>{values.analysts.unitDesc}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput onChange={(event, newValue) => { setPlan({...plan, 'analystsCount': newValue}); setAnalystsCount(newValue)}} defaultValue={values.analysts.minimum} value={analystsCount} aria-label="Quantity Input" step={values.analysts.rule} min={values.analysts.minimum} max={99} />
                    </Grid>
                  </Grid>
                </Grid>}
                <Grid item xs={12}>
                  <Box my={2}>
                      <Button
                      color='secondary'
                      fullWidth
                      size="large"
                      type='submit'
                      variant="contained"
                      onClick={(e) => handleTabChange(e, 2)}
                      disabled={!dropdownValue}
                      style={{ textTransform: 'none'}}
                      >
                        {'Continue'}
                      </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
                <Grid container rowSpacing={2}>
                  <Grid item className={classes.order} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h3'>Order summary</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Plan: {values.title}</Typography>
                        <Typography gutterBottom variant='body1'>Billing: {values.billing}</Typography>
                        {!businessView && <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>SAR {values.price} x 12 months <span>SAR {cost(values.price*12)}</span> </Typography>}
                      </Grid>
                      {!!businessView && !!fullAccessCount && <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Full Access: {fullAccessCount} users</Typography>
                        <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>{fullAccessCount} x SAR {values.price} x 12 months <span>SAR {cost(fullAccessCount*values.price*12)}</span> </Typography>
                      </Grid>}
                      {!!businessView && !!analystsCount && analystsCount > 1 && <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Analysts: {analystsCount}</Typography>
                        <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>{analystsCount} x SAR {values.analystsPrice} x 12 months <span>SAR {cost(analystsCount*values.analystsPrice*12)}</span> </Typography>
                      </Grid>}
                    </Grid>
                  </Grid>
                  <Grid item className={classes.total} xs={12}>
                    <Typography style={{display:'flex'}} gutterBottom variant='h5'>Total<Typography style={{paddingLeft: '2px'}} variant='body1'> (VAT included)</Typography></Typography>
                    <Typography gutterBottom variant='body1'><span>SAR {cost(getTotal())}</span> </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.orderDetails}>
                      <Typography gutterBottom variant='h5'>{values.title}</Typography>
                      <List sx={{ color: 'GrayText', textAlign: 'left', marginTop: '10px' }}>
                        {list.map((r, idx) => {
                          return(
                          <ListItem pl={0} key={idx}>
                            <ListItemIcon style={{minWidth: '30px'}}>
                              <CheckCircleIcon fontSize='small' color='secondary' />
                            </ListItemIcon> 
                            <Typography color='GrayText' variant='body1'>{r}</Typography> 
                          </ListItem>)
                        })}
                    </List>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Plan;
