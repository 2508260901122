import React, {useEffect, useContext}  from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  Alert,
  Autocomplete
} from '@mui/material';
import Page from '../../../components/Page';
import { makeStyles } from '@mui/styles';
import { CheckOutContext } from '../context';
import * as Yup from 'yup';
import { Formik } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh !important',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.white,
  },
  order: {
    backgroundColor: '#F7F8FA',
    padding: theme.spacing(1,3),
    borderBottom: '1px solid #EDEEEE'
  },
  total: {
    display:'flex',
    padding: theme.spacing(2,3),
    backgroundColor: '#F7F8FA',
    justifyContent: 'space-between'
  },
  orderDetails: {
    padding: theme.spacing(2,3),
    border: '1px solid #EDEEEE'
  }
}));

const Billing = () => {
  const classes = useStyles();
  const checkOutContext = useContext(CheckOutContext);
  const { plan, billingUser, setBillingUser, detailsList, handleTabChange, countries, email } = checkOutContext;
  const [businessView, setBusinessView] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [country, setCountry] = React.useState(billingUser.country);

  const handleCountryChange = (event, newValue) => {
    setCountry(newValue)
  }

  const cost = (val) => {
    if(!!val) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return 0;
  }

  const getTotal = () => {
    if(!!businessView && !!plan.fullAccessCount && plan.analystsCount === 0) {
      return (plan.fullAccessCount*values.price*12)
    } 
    else if(!!businessView && !!plan.analystsCount && plan.analystsCount > 1) {
      return (plan.fullAccessCount*values.price*12 + plan.analystsCount*values.analystsPrice*12)
    } 
    else if(!businessView) {
      return (values.price*12)
    }
  }

  useEffect(() => {
    if(plan && plan.plan === 'Business plans (multiple users)') {
      setBusinessView(true)
    } else {
      setBusinessView(false)
    }
    if(plan && plan.title) {
        let d = detailsList.find((aa) => aa.title === plan.title)
        setValues(d);
    }
  }, [plan, detailsList]);

  return (
    <Page
      className={classes.root}
      title={'Crux CX - Contact Us'}
    >
      <Container maxWidth="xl" >
        <Grid container display='flex' flexDirection= 'row' justifyContent= 'center' alignContent= 'center' spacing={4}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs= {12}>
                  <Typography gutterBottom variant='h3'>Billing Address</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            email: email ? email : billingUser.email,
                            firstName: billingUser.firstName,
                            lastName: billingUser.lastName
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().required("Email is required").email().matches(
                                /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                                "Enter a valid email address"
                            ),
                        })}
                        onSubmit={(values, actions) => {
                            setBillingUser({price: getTotal() ,firstName: values.firstName, lastName: values.lastName, country: country, email: values.email})
                            handleTabChange(null,4)
                        }}
                        >
                        {({
                            errors,
                            status,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                        <form onSubmit={handleSubmit}>
                                {status && status.error &&
                                <Box my={2}>
                                    <Alert variant="outlined" severity="error">
                                        {status.error}
                                    </Alert>
                                </Box>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant='h5'>First name</Typography>
                                        <TextField
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                            fullWidth
                                            size='small'
                                            style={{marginTop: '5px'}}
                                            margin="normal"
                                            name="firstName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.firstName}
                                            variant="outlined"
                                            color='text'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant='h5'>Last name</Typography>
                                        <TextField
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                            fullWidth
                                            size='small'
                                            style={{marginTop: '5px'}}
                                            margin="normal"
                                            name="lastName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.lastName}
                                            variant="outlined"
                                            color='text'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant='h5'>Country</Typography>
                                        <Autocomplete
                                            size='small'
                                            name='country'
                                            value={country}
                                            onChange={handleCountryChange}
                                            options={countries}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant='h5'>Billing email</Typography>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            fullWidth
                                            size='small'
                                            style={{marginTop: '5px'}}
                                            margin="normal"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.email}
                                            variant="outlined"
                                            color='text'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                <Box my={2}>
                                    <Button
                                    color='secondary'
                                    fullWidth
                                    disabled={!values.firstName || !values.lastName}
                                    size="large"
                                    type='submit'
                                    variant="contained"
                                    style={{ textTransform: 'none'}}
                                    >
                                        {'Enter payment details'}
                                    </Button>
                                </Box>
                                </Grid>
                                </Grid>
                                
                        </form>
                        )}
                    </Formik>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
                <Grid container rowSpacing={2}>
                  <Grid item className={classes.order} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography gutterBottom variant='h3'>Order summary</Typography>
                        <Button variant='outlined' color='secondary' onClick={(e) => handleTabChange(e, 0)} >Edit</Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Plan: {values.title}</Typography>
                        <Typography gutterBottom variant='body1'>Billing: {values.billing}</Typography>
                        {!businessView && <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>SAR {values.price} x 12 months <span>SAR {cost(values.price*12)}</span> </Typography>}
                      </Grid>
                      {!!businessView && !!plan.fullAccessCount && <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Full Access: {plan.fullAccessCount} users</Typography>
                        <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>{plan.fullAccessCount} x SAR {values.price} x 12 months <span>SAR {cost(plan.fullAccessCount*values.price*12)}</span> </Typography>
                      </Grid>}
                      {!!businessView && !!plan.analystsCount && plan.analystsCount > 1 && <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>Analysts: {plan.analystsCount}</Typography>
                        <Typography gutterBottom style={{display:'flex', justifyContent: 'space-between'}} variant='body1'>{plan.analystsCount} x SAR {values.analystsPrice} x 12 months <span>SAR {cost(plan.analystsCount*values.analystsPrice*12)}</span> </Typography>
                      </Grid>}
                    </Grid>
                  </Grid>
                  <Grid item className={classes.total} xs={12}>
                    <Typography style={{display:'flex'}} gutterBottom variant='h5'>Total<Typography style={{paddingLeft: '2px'}} variant='body1'> (VAT included)</Typography></Typography>
                    <Typography gutterBottom variant='body1'><span>SAR {cost(getTotal())}</span> </Typography>
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Billing;
