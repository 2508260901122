import React, {useState} from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Box,
    Button,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import MergeOutlinedIcon from '@mui/icons-material/MergeOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import theme from '../../../theme';

const StyledDrawer = styled(Drawer)({
    // position: "absolute", //imp
    width: 90, //drawer width
    "& .MuiDrawer-paper": {
      width: 90, //drawer width
      position: "relative", //imp
      transition: "none !important"
    }
});

const StyledOptionsDrawer = styled(Drawer)({
    // position: "absolute", //imp
    width: 390, //drawer width
    overflow: "auto",
    left: 0,
    maxHeight: 690,
    "& .MuiDrawer-paper": {
      width: 390, //drawer width
      position: "relative", //imp
      transition: "none !important"
    }
})
console.log(document.getElementById('drawer1'), document.getElementById('heading'))

const SideBar = () => {
    const [open, setOpen] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedOptionsIndex, setSelectedOptionsIndex] = React.useState(null);
    const [hover, setHover] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(0);
    const onHover = (event, index) => {
        setHover(true);
        setHoverIndex(index)
    };
    const onLeave = () => {
        setHover(false);
        setHoverIndex(null)
      };  

    const selectedList = [
        {   id: 1,
            name: 'BUILD',
            options: [{ id: 1, text: 'Multiple Choice'},
                { id: 2, text: 'Checkboxes'},
                { id: 3, text: 'Star rating'},
                { id: 4, text: 'Dropdown'},
                { id: 5, text: 'Matrix / Rating Scale'},
                { id: 6, text: 'Best Worst Scale'},
                { id: 7, text: 'File Upload'},
                { id: 8, text: 'Ranking'},
                { id: 9, text: 'Net Promoter Score'},
                { id: 10, text: 'Image Choice'},
                { id: 11, text: 'Click Map'},
                { id: 12, text: 'Comment Box'},
                { id: 13, text: 'Single Textbox'},
                { id: 14, text: 'Slider'},
                { id: 15, text: 'Matrix of Dropdown Menus'},
                { id: 16, text: 'Multiple Textboxes'},
                { id: 17, text: 'Date / Time'},
                { id: 18, text: 'Name'},
                { id: 19, text: 'Email Address'},
                { id: 20, text: 'Phone'},
                { id: 21, text: 'Address'},
                { id: 22, text: 'Text'},
                { id: 23, text: 'Image'},
                { id: 24, text: 'Text A/B Test'},
                { id: 25, text: 'Image A/B Test'},
                { id: 26, text: 'Intro Page'},
                { id: 27, text: 'New Page'},
                { id: 28, text: 'Page Break'},
            ]
        },
        {},
        {   id: 3,
            name: 'LOGIC',
            options: [{ id: 1, text: 'Page Skip Logic'},
                { id: 2, text: 'Page Randomization'},
                { id: 3, text: 'Question Randomization'},
                { id: 4, text: 'Block Randomization'},
                { id: 5, text: 'Quota'},
                { id: 6, text: 'Custom Variables'}
            ]
        }
    ]

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if(index===9) {
            setOpen(!open);
            console.log(open)
            setSelectedIndex(0)
        }
    };

    const handleOptionsListItemClick = (event, index) => {
        setSelectedOptionsIndex(index);
    };

    const list = [
        {
            text: 'Build',
            id: 1
        },
        {
            text: 'Style',
            id: 2
        },
        {
            text: 'Logic',
            id: 3
        },
        {
            text: 'Options',
            id: 4
        },
        {
            text: 'Question Bank',
            id: 5
        },
        {
            text: 'Format',
            id: 6
        },
        {
            text: 'Paid Features',
            id: 7
        },
        {
            text: 'Print',
            id: 8
        }
    ];
    const getStatusIcon = (id) => {
        var result = <ApartmentOutlinedIcon />;
        switch (id) {
        case 1:
            result = <ApartmentOutlinedIcon />;
            break;
        case 2:
            result = <BrushOutlinedIcon />;
            break;
        case 3:
            result = <MergeOutlinedIcon /> ;
            break;
        case 4:
            result = <TuneOutlinedIcon />;
            break;
        case 5:
            result = <ReceiptLongOutlinedIcon />;
            break;
        case 6:
            result = <BallotOutlinedIcon />;
            break;
        case 7:
            result = <PaidOutlinedIcon />;
            break;
        case 8:
            result = <LocalPrintshopOutlinedIcon />;
            break;

          default:
            result =<LocalPrintshopOutlinedIcon />;
            break;
        }
        return result;
    }
    return (
    <Box display='flex' flexDirection='row'>
        <StyledDrawer id="drawer1" variant="permanent" open={true}>
            <List sx={{paddingTop: 0, paddingBottom: 0}}>
            {list.map((l, i) => (
                <ListItem selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i)} key={i} disablePadding 
                sx={{ 
                    "&:hover": {
                        "& .MuiListItemIcon-root": {
                        color: theme.palette.primary.main,
                        },
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: theme.palette.primary.main,
                        // color: "white",
                        "& .MuiListItem-root": {
                            backgroundColor: theme.palette.primary.main,
                        },
                        "& .MuiListItemIcon-root": {
                        color: "white"
                        },
                        "& .MuiListItemText-primary": {
                        color: "white",
                        }
                    },
                    "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiListItem-root": {
                            backgroundColor: theme.palette.primary.main,
                        },
                        "& .MuiListItemIcon-root": {
                        color: "white"
                        },
                        "& .MuiListItemText-primary": {
                        color: "white",
                        }
                    }, 
                    display: 'block' 
                    }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            px: 2.5,
                            py:1.5,
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom: '1px solid #D0D2D3',
                            textAlign: 'center'
                        }}
                    >
                        <ListItemIcon
                            sx={[
                                {
                                minWidth: 0,
                                justifyContent: 'center',
                                }
                            ]}
                            >
                            {getStatusIcon(l.id)}
                        </ListItemIcon>
                        <ListItemText
                            primary={l.text}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
            <ListItem selected={selectedIndex === 9} onClick={(event) => handleListItemClick(event, 9)} disablePadding sx={{ 
                "&:hover": {
                    "& .MuiListItemIcon-root": {
                    color: theme.palette.primary.main,
                    },
                },
                display: 'block' 
                }}>
                <ListItemButton
                    // onClick={open ? handleDrawerClose() : handleDrawerOpen()}
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                        py:1.5,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                    <ListItemIcon
                        sx={[
                            {
                            minWidth: 0,
                            justifyContent: 'center',
                            }
                        ]}
                        >
                        {open ? <ArrowBackIosOutlinedIcon /> : <ArrowForwardIosOutlinedIcon />}
                    </ListItemIcon>
                    <ListItemText
                        primary={open ? 'Collapse' : 'Expand'}
                    />
                </ListItemButton>
            </ListItem>
            </List>
        </StyledDrawer>
        <Box display='flex' flexDirection='column'>
            <Typography id="heading" variant='h6' style={{ display: selectedList[selectedIndex] && selectedList[selectedIndex].name ? 'block' : 'none', border: '1px solid #D0D2D3', fontSize: theme.typography.h6.fontSize, fontWeight: '600',}} px={3} py={2}>{selectedIndex >= 0 && selectedList[selectedIndex] ? selectedList[selectedIndex].name : ''}</Typography>
            <StyledOptionsDrawer variant="persistent" open={open}>
                <List sx={{paddingTop: 0, paddingBottom: 0}}>
                {selectedList.find(sl => sl.id === selectedIndex+1) && selectedList.find(sl => sl.id === selectedIndex+1).options.map((l, i) => (
                    <ListItem selected={selectedOptionsIndex === i}
                    onMouseEnter={(e) => onHover(e,i)}
                    onMouseLeave={onLeave}
                    onClick={(event) => handleOptionsListItemClick(event, i)} key={i} disablePadding 
                    sx={{ 
                        backgroundColor: '#eeeeee',
                        "&:hover": {
                            backgroundColor: "#bdbdbd",
                        },
                        "&.Mui-selected": {
                            backgroundColor: "#bdbdbd",
                        }, 
                        display: 'block' 
                        }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                px: 2.5,
                                py:1.5,
                                display: 'flex',
                                flexDirection: 'row',
                                borderBottom: '1px solid #D0D2D3',
                                textAlign: 'left'
                            }}
                        >
                            <ListItemIcon
                                sx={[
                                    {
                                    mx:1,
                                    minWidth: 0,
                                    justifyContent: 'center',
                                    }
                                ]}
                                >
                                {getStatusIcon(l.id)}
                            </ListItemIcon>
                            <ListItemText
                                primary={l.text}
                            />
                            <Button
                                style={{ marginRight: '10px', backgroundColor: '#FFF', borderColor: 'black',
                                    "&:hover": {
                                    cursor: "pointer",
                                    backgroundColor: 'transparent', 
                                    borderColor: 'black',
                                    },
                                    display: (hover && hoverIndex === i) ? 'block' : 'none'
                                }}
                                variant="outlined"
                                >
                                <Typography variant="h6">
                                {'ADD'}
                                </Typography>
                            </Button>
                        </ListItemButton>
                    </ListItem>
                ))}
                </List>
            </StyledOptionsDrawer>
        </Box>
    </Box>
    );
};
export default SideBar;
