import React, {useContext, useEffect} from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import { useMediaQuery } from "@mui/material";
import theme from '../../../theme';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Box,
    InputAdornment,
    SvgIcon,
    colors,
    Card,
    CardContent,
    List,
    ListItem,
} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import {SurveyContext} from '../context';
import RouteIcon from '@mui/icons-material/Route';
import SurveyCard from './SurveyCard';
import SurveyList from './SurveyList';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        paddingBottom: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 3)
        }
    },
    section: {
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1, 3)
        }
    },
    card: {
        textAlign: 'left',
        padding: '4px 0 0 10px',
    }
}));

const SurveyListView = () => {
    const classes = useStyles();
    const surveyContext = useContext(SurveyContext);
    const {surveys} = surveyContext;
    const [view,setView] = React.useState('card');
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(!!mobileDevice){
            setView('card');
        }
    }, [mobileDevice]);

    const handleViewChange = (event, newValue) => {
        setView(newValue);
    };

    return (
        <Page title="Crux" className={classes.root}>
            <Grid container spacing={1} className={classes.section}>
                <Grid item my={4} xs={12} md={12}>
                    <Typography gutterBottom variant='h2'>Welcome</Typography>
                </Grid>
                <Grid  item xs={12} md={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                size='small'
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <SvgIcon
                                        fontSize="small"
                                        color="action"
                                    >
                                        <SearchIcon />
                                    </SvgIcon>
                                    </InputAdornment>
                                )
                                }}
                                placeholder="Search survey"
                                variant="outlined"
                                // onChange={onChangeSearchTitle}
                            />
                        </Grid>
                        {!mobileDevice && <Grid item xs={12} md={4}>
                            <ToggleButtonGroup
                                size='small'
                                color='primary'
                                value={view}
                                exclusive
                                onChange={handleViewChange}
                                aria-label="text alignment">
                                <ToggleButton disabled={view === 'card'} value="card">
                                    <ViewModuleIcon size='small'/>
                                </ToggleButton>
                                <ToggleButton disabled={view === 'list'} value="list">
                                    <ReorderIcon size='small'/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>}
                        <Grid item xs={12} md={4} style={{textAlign: 'right'}}>
                            <Button
                            style={{ textTransform: 'none', marginRight: '10px', borderColor: 'primary', backgroundColor: 'primary',
                                "&:hover": {
                                cursor: "pointer",
                                backgroundColor: 'primary'
                                },
                            }}
                            component={RouterLink}
                            to={`${process.env.PUBLIC_URL}/app/create`}
                            variant="contained"
                            >
                                <Typography color={'#FFFFFF'} variant="h5">
                                {'Create survey'}
                                </Typography>
                            </Button>
                        </Grid>
                        {view === 'card' && <Grid pr={4} item xs={12} md={12}>
                            <Grid container spacing={3}>
                                {surveys && surveys.map((r, idx) => {
                                return(
                                <Grid item key={r.id} xs={12} md={6}>
                                    <SurveyCard survey={r} />
                                </Grid>
                                )})}
                            </Grid>
                        </Grid>}
                        {view === 'list' && <Grid pr={4} item xs={12} md={12}>
                            <Grid container>
                                {surveys && surveys.map((r, idx) => {
                                return(
                                <Grid item key={r.id} xs={12} md={12}>
                                    <SurveyList survey={r} />
                                </Grid>
                                )})}
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card className= {classes.card} 
                    sx={{
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        boxShadow: 'rgb(237, 238, 238) 0px 0px 0px 2px',
                        transition: 'border-color 0.2s, box-shadow 0.2s',
                        borderRadius: '24px',
                        textDecoration: 'none',
                        backgroundColor: colors.grey[100],
                        }}>
                        <CardContent>
                            <Grid container>
                                <Grid item my={2} xs={12} md={12}>
                                    <RouteIcon sx={{ fontSize:'40px'}} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant='subtitle2'>{'Get more with a paid plan'}</Typography>
                                </Grid>
                                <Grid item mx={2} xs={12} md={12}>
                                    {/* <Typography gutterBottom variant='body2'>{'Get more responses'}</Typography> */}
                                    <List sx={{ listStyleType: 'disc' }}>
                                        <ListItem sx={{ display: 'list-item', padding: '2px' }}>
                                            <Typography color='GrayText' variant='h6'>{'Get more responses'}</Typography> 
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', padding: '2px' }}>
                                            <Typography color='GrayText' variant='h6'>{'Use advanced question types'}</Typography> 
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item', padding: '2px' }}>
                                            <Typography color='GrayText' variant='h6'>{'Collaborate with others'}</Typography> 
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item my={2} xs={12} md={12}>
                                    <Button
                                    fullWidth
                                    sx={{ textTransform: 'none', marginRight: '10px', borderColor: 'primary', backgroundColor: 'primary',
                                        "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: 'primary'
                                        },
                                    }}
                                    component={RouterLink}
                                    to={`${process.env.PUBLIC_URL}/pricing`}
                                    variant="contained"
                                    >
                                        <Typography color={'#FFFFFF'} variant="h5">
                                        {'See plans'}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box mt={3} display={'flex'} flexDirection={'column'}>
                        <Typography my={2} variant="h3">
                            Tips to get you started
                        </Typography>
                        <Typography my={1} variant="subtitle1">
                            <RouterLink to="/" style={{ color: colors.blue[500] }} >Creating your survey</RouterLink>
                        </Typography>
                        <Typography my={1} variant="subtitle1">
                            <RouterLink to="/" style={{ color: colors.blue[500] }} >Choosing question types</RouterLink>
                        </Typography>
                        <Typography my={1} variant="subtitle1">
                            <RouterLink to="/" style={{ color: colors.blue[500] }} >Sending your survey</RouterLink>
                        </Typography>
                        <Typography my={1} variant="subtitle1">
                            <RouterLink to="/" style={{ color: colors.blue[500] }} >Sharing survey results</RouterLink>
                        </Typography>
                        <Typography my={1} variant="subtitle1">
                            <RouterLink to="/" style={{ color: colors.blue[500] }} >Sharing survey results</RouterLink>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Page >
    );
};
export default SurveyListView;
