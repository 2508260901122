import React from 'react';
import {
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: '1px solid #D0D2D3',
        backgroundColor: theme.palette.background.dark,
        padding: theme.spacing(3)
    },
    item: {
        padding: theme.spacing(0, 1),
        borderRight: "0.5px solid lightgray",
        '&:last-child': {
            borderRight: 'none'
        }
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <Grid className={classes.container} container spacing={2} justifyContent={'center'}>
            <Grid item xs={3} md={1}>
                <Typography variant='subtitle2'>Community:</Typography>
            </Grid>
            <Grid item xs={9} md={11}>
                <Grid container>
                {/* <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Facebook</Typography></Grid> */}
                <Grid item className={classes.item}><Typography variant='body2' target='_blank' component={RouterLink} to={`https://www.instagram.com/crux360.ai/`} sx={{fontSize: theme.typography.h6.fontSize}}><InstagramIcon color='#546e7a' /></Typography></Grid>
                <Grid item className={classes.item}><Typography variant='body2' target='_blank' component={RouterLink} to={`https://x.com/SolutionsC48035`} sx={{fontSize: theme.typography.h6.fontSize}}><XIcon color='#546e7a' /></Typography></Grid>
                <Grid item className={classes.item}><Typography variant='body2' target='_blank' component={RouterLink} to={`https://www.linkedin.com/company/cruxtechnologysolutions`} sx={{fontSize: theme.typography.h6.fontSize}}><LinkedInIcon color='#546e7a' /></Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} md={1}>
                <Typography variant='subtitle2'>About Us:</Typography>
            </Grid>
            <Grid item xs={9} md={11}>
                <Grid container>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Leadership</Typography></Grid>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Careers</Typography></Grid>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Office Locations</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} md={1}>
                <Typography variant='subtitle2'>Policies:</Typography>
            </Grid>
            <Grid item xs={9} md={11}>
                <Grid container>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Terms of Use</Typography></Grid>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Privacy Notice</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} md={1}>
                <Typography variant='subtitle2'>Contact Us:</Typography>
            </Grid>
            <Grid item xs={9} md={11}>
                <Grid container>
                <Grid item className={classes.item}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Crux Technology Solutions<br></br>License Number: 2434443.01<br></br>Address: Sharjah Media City, Sharjah, UAE.<br></br>Mobile: +971 508511929<br></br>Email: contact@crux360.ai </Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} ><Divider sx={{borderColor:'#D0D2D3'}} variant='fullwidth'/></Grid>
            <Grid item xs={12}><Typography variant='body2' sx={{fontSize: theme.typography.h6.fontSize}}>Copyright © 2025 Crux</Typography></Grid>
        </Grid>
        </div>
    );
};
export default Footer;
