import React from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import Label from '../../../components/Label';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Typography,
    Button,
    Box,
    colors,
    Link,
    IconButton,
    MenuItem,
    ListItemText,
    Grow ,
    ClickAwayListener,
    Paper,
    Popper,
    MenuList 
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

const getMakerStatusColor = (status) => {
    var result = colors.grey[600]
    switch (status) {
      case 'NEW':
        result = "#221afb";
        break;
      case 'PENDING':
        result = colors.amber[600];
        break;
      case 'APPROVED':
        result = colors.green[600];
        break;
      case 'REJECTED':
        result = colors.red[600];
        break;
      case 'OPEN':
        result = colors.blue[800];
        break;
      default:
        result = colors.grey[600];
        break;
    }
    return result;
}

const getStatusIcon = (stats) => {
    let status = 0;
    if(stats.responsesCount >= 0 && stats.responsesCount < stats.responsesTotalCount) {
        status = 1
    } else if(stats.issues === 0){
        status = 2
    }
    var result = <InfoIcon />;
    switch (status) {
      case 1:
        result = <LayersOutlinedIcon />;
        break;
      case 2:
        result = <DoneOutlinedIcon />;
        break;
      default:
        result = <InfoIcon />;
        break;
    }
    return result;
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    section: {
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1, 3)
        }
    },
    list: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e6e9eb',
    },
    card: {
        textAlign: 'left',
        padding: '4px 0 0 10px',
    },
    menu: {
        boxShadow: 'none',
    },
    item: {
        padding: theme.spacing(10)
    }
}));

const SurveyList = (props) => {
    const r = props.survey;
    console.log(r)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popper' : undefined;
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
       setAnchorEl(null);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setAnchorEl(false)
        }
    }
   
    return (
        <Page title="Crux" className={classes.root}>
            <Box className={classes.list}>
                <Grid item xs={12} md={1}>
                    <Label
                    color={getMakerStatusColor(r.status)}
                    >
                    {r.status}
                    </Label>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Link
                        color="textPrimary"
                        component={RouterLink} to={'/app/surveys/edit?id='+r.id}
                        sx={{ textDecoration: 'none' , "&:hover": {  color:colors.blue[700], textDecoration: 'underline'} }}
                        variant="h5"
                        >
                            <Typography sx={{ "&:hover": { color:colors.blue[700],} }} gutterBottom color='textPrimary' variant='subtitle2'>{r.name}</Typography> 
                        </Link>
                    <Typography color='grayText' variant='subtitle1'>{ 'Modified ' + r.updatedOn}</Typography> 
                </Grid>
                <Grid sx={{display: 'flex'}} item xs={12} md={3}>
                    {getStatusIcon(r.stats)}
                    <Typography ml={1} color='textPrimary' variant='subtitle2'>{r.stats.questionsCount === 0 ? 'Add Questions' : r.stats.issues === 0 ? 'No issues' : (r.stats.responsesCount + '/' + r.stats.responsesTotalCount + ' responses')}</Typography> 
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        style={{ textTransform: 'none', marginRight: '10px', borderColor: 'black', backgroundColor: '#FFFFF',
                            "&:hover": {
                            cursor: "pointer",
                            backgroundColor: '#FFFF'
                            },
                        }}
                        variant="outlined"
                        >
                        <Typography color='textPrimary' variant="h5" component={RouterLink} to={'/app/surveys/edit?id='+r.id}>
                            {r.stats.questionsCount === 0 ? 'Edit survey' : (!!r.stats.issues || r.stats.responsesCount === 0) ? 'Send survey' : 'Analyze results'}
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} md={1} style={{textAlign: 'right'}}>
                    <IconButton sx={{"&:hover": { backgroundColor: "#fff" }}} onClick={handleClick} aria-label="settings">
                        {open ? <CloseIcon sx={{color: 'black'}} /> : <MoreHorizIcon sx={{color: 'black'}} />}
                    </IconButton>
                    <Popper open={open} anchorEl={anchorEl} transition disablePortal={false}>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            <ListItemText>
                                                <Typography p={2} variant="subtitle1">{r.name}</Typography>
                                            </ListItemText>
                                            <MenuItem sx={{borderTop: '1px solid #e6e9eb'}} component={RouterLink} to={'/app/surveys/edit?id='+r.id}>
                                                <Typography variant="subtitle1">Edit Survey</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={handleClick}>
                                                <Typography variant="subtitle1">Preview Survey</Typography>
                                            </MenuItem>
                                            <MenuItem  onClick={handleClick}>
                                                <Typography variant="subtitle1">Send Survey</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={handleClick}>
                                                <Typography variant="subtitle1">Analyze results</Typography>
                                            </MenuItem>
                                            <MenuItem  onClick={handleClick}>
                                            <Typography variant="subtitle1">Buy targeted responses</Typography>
                                            </MenuItem>
                                            <MenuItem sx={{borderTop: '1px solid #e6e9eb'}} onClick={handleClick}>
                                            <Typography variant="subtitle1">Make a copy</Typography>
                                            </MenuItem>
                                            <MenuItem  onClick={handleClick}>
                                            <Typography variant="subtitle1">Share</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={handleClick}>
                                            <Typography variant="subtitle1">Delete</Typography>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Box>
        </Page >
    );
};
export default SurveyList;
