import React from 'react';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import theme from '../../theme';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import { ExpandMore } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  banner: {
    maxWidth: '100%',
  },
  logo: {
    width: '100px',
  },
  video: {
    maxWidth: '100%',
  },
  section: {
    textAlign: 'left',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6,15),
    }
  },
  section5: {
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6,15),
    }
  },
  section1: {
    textAlign: 'center',
    padding: theme.spacing(5,0),
    backgroundColor: theme.palette.background.light,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3,8),
      marginBottom: theme.spacing(6)
    }
  },
  section2: {
    textAlign: 'left',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3,8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,2),
    }
  },
  section3: {
    textAlign: 'left',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.light,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3,8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0,2),
    }
  },
  section4: {
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6,15),
    }
  },
  questions: {
    boxShadow: 'none'
  }
}));

const HomeView = () => {
  const classes = useStyles();
  const currentUser = AuthService.getCurrentUser();

  if(!!currentUser) {
    return <Navigate to="/app/home" />
  }

  return (
    <Page
      title="Crux"
      className={classes.root}
    >
      <Grid container spacing={6} className={classes.section}> 
        <Grid item my={6} xs={10} md={6}>
          <Typography gutterBottom color={'#FFFFFF'} sx={{fontWeight: '500', fontSize: '48px', letterSpacing: 'normal'}} variant='h1'>Meet your new personal survey expert</Typography>
          <Typography color={'#FFFFFF'} sx={{fontWeight: theme.typography.h6.fontWeight, lineHeight: theme.typography.h2.lineHeight}} variant='h4'>Powered by AI and machine learning, Crux Genius® helps you create better surveys, gather higher-quality responses and spot insights quicker.</Typography>
          <Button
            style={{ marginTop: theme.spacing(5), textTransform: 'none', borderColor: '#FFFFFF', color: '#FFFFFF', backgroundColor: '#FFFFFF'}}
            component={RouterLink}
            size='large'
            to={!!currentUser ? `${process.env.PUBLIC_URL}/app/create` : "https://app.crux360.ai/session/signup"}
            variant="outlined"
          >
            <Typography color={'primary'} variant="h5">
            { !!currentUser ? 'Create Survey' : 'Sign Up Free'}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <img
            className={classes.banner}
            src="../../static/banner_1.png"
            alt='VS'
            loading="lazy"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.section1} > 
        <Grid item m={5} xs={12} md={12}>
          <Typography gutterBottom sx={{ lineHeight: theme.typography.h1.lineHeight, fontWeight: '600', fontSize: '38px', letterSpacing: 'normal'}} variant='h1'>Our scale and expertise set us apart</Typography>
          <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Why is our AI technology so powerful? Because for nearly 25 years, we’ve been gathering data on survey structure, question content and respondent behaviour. That means more accurate, helpful recommendations, based on industry-leading expertise.</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom sx={{ lineHeight: theme.typography.h1.lineHeight, fontWeight: '600', fontSize: '45px', letterSpacing: 'normal'}} variant='h1'>84B+</Typography>
              <Typography sx={{padding: theme.spacing(0,12), lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}}variant='subtitle1'>Questions answered with Crux</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom sx={{lineHeight: theme.typography.h1.lineHeight, fontWeight: '600', fontSize: '45px', letterSpacing: 'normal'}} variant='h1'>2M+</Typography>
              <Typography sx={{padding: theme.spacing(0,12), lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Daily survey responses</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom sx={{lineHeight: theme.typography.h1.lineHeight, fontWeight: '600', fontSize: '45px', letterSpacing: 'normal'}} variant='h1'>2.4M+</Typography>
              <Typography sx={{padding: theme.spacing(0,12), lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Daily AI predictions</Typography>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
      <Grid container mt={1} spacing={2} className={classes.section2}> 
        <Grid sx={{textAlign: 'center'}} item my={5} xs={12} md={12}>
          <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h1'>Build better surveys faster</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <video
            className={classes.video}
            src={`${process.env.PUBLIC_URL}/static/final2.mp4`}
            muted
            autoPlay
            loop 
          />
        </Grid>
        <Grid item xs={10} md={6}>
          <Grid container mt={1} spacing={2}> 
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>BUILD WITH AI</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Write a short prompt; get a whole survey</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Need to send a survey and don’t know where to start? No problem! Crux AI helps you confidently create a high-quality survey from scratch in as little as 30 seconds.</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{ marginTop: theme.spacing(2), fontWeight: '700', letterSpacing: 'normal'}} variant='subtitle1'>How it works:</Typography>
              <List sx={{ listStyleType: 'disc', paddingLeft: theme.spacing(2) }}>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Use one of our sample prompts or write your own description of your survey goals</Typography>} />
                </ListItem>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Using GPT, Crux AI will automate the survey creation process for you</Typography>} />
                </ListItem>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>To finalise your survey, simply refine your prompt, or edit the generated survey directly</Typography>} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                style={{ textTransform: 'none', backgroundColor: '#FFF'}}
                component={RouterLink}
                size='large'
                color='primary'
                to={!!currentUser ? `${process.env.PUBLIC_URL}/app/create` : "https://app.crux360.ai/session/signup"}
                variant="outlined"
              >
                <Typography color={'primary'} variant="h5">
                { !!currentUser ? 'Create Survey' : 'Try it now'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={2} className={classes.section2}> 
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <img
                className={classes.banner}
                src="../../static/answer-question.png"
                alt='VS'
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>QUESTION & ANSWER GENIUS</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Ask the right questions the right way</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Start typing a survey question and Crux AI will recommend the best question type to use and allow you to autofill a set of balanced answer choices proven to eliminate bias and capture accurate data. </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <img
                className={classes.banner}
                src="../../static/expert-tip.png"
                alt='VS'
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>SURVEY SCORE</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Improve your survey with expert tips</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Crux AI will review and score your survey draft, use machine learning to detect issues with survey structure or question formats and offer ways to improve the respondent experience and increase completion rate. </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} textAlign='center'>
          <Button
            style={{ textTransform: 'none', backgroundColor: '#FFF'}}
            // component={RouterLink}
            size='large'
            color='primary'
            // to={!!currentUser ? `${process.env.PUBLIC_URL}/` : "https://app.crux360.ai/session/signup"}
            variant="outlined"
          >
            <Typography color={'primary'} variant="h5">
            { 'Get started'}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={2} className={classes.section3}> 
        <Grid sx={{textAlign: 'center'}} item my={5} xs={12} md={12}>
          <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h1'>Get higher-quality insights to inform your next brilliant move</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <video
            className={classes.video}
            src={`${process.env.PUBLIC_URL}/static/sentiment-analysis.mp4`}
            muted
            autoPlay
            loop 
          />
        </Grid>
        <Grid item xs={10} md={6}>
          <Grid container mt={1} spacing={2}> 
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>SENTIMENT ANALYSIS</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Easily see how respondents really feel</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Stop spending hours sifting through open-ended responses to find patterns. Crux AI turns unstructured data into digestible insights.</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{ marginTop: theme.spacing(2), fontWeight: '700', letterSpacing: 'normal'}} variant='subtitle1'>How it works:</Typography>
              <List sx={{ listStyleType: 'disc', paddingLeft: theme.spacing(2) }}>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Collect open-ended survey responses</Typography>} />
                </ListItem>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Using natural language processing (NLP), Crux AI will categorise responses as Positive, Neutral or Negative</Typography>} />
                </ListItem>
                <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                  <ListItemText sx={{ display: 'list-item' }}  primary={<Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>You can then filter results by sentiment to dig even deeper</Typography>} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* notes */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={5} className={classes.section3}> 
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <img
                className={classes.banner}
                src="../../static/poor-quality-response.png"
                alt='VS'
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>RESPONSE QUALITY</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Remove poor-quality responses</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Avoid making decisions based on flawed data. Crux AI will scan your survey results for speeding, straightlining, swear words or gibberish and allow you to filter out anything that doesn’t meet quality standards. </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <img
                className={classes.banner}
                src="../../static/insights-filters.png"
                alt='VS'
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontSize: '14px', letterSpacing: '2px'}} variant='subtitle1'>AI-POWERED INSIGHTS</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Spot trends you may have otherwise missed</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ lineHeight: theme.typography.h2.lineHeight, fontSize: '16px', letterSpacing: 'normal'}} variant='subtitle1'>Available in our market research solutions, Crux AI uses machine learning to comb through your data and surface statistically significant trends, like which segments preferred a specific ad or product concept. </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} mt={2} textAlign='center'>
          <Button
            style={{ textTransform: 'none', backgroundColor: '#FFF'}}
            // component={RouterLink}
            size='large'
            color='primary'
            // to={!!currentUser ? `${process.env.PUBLIC_URL}/` : "https://app.crux360.ai/session/signup"}
            variant="outlined"
          >
            <Typography color={'primary'} variant="h5">
            { 'See plans'}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={1} className={classes.section4}> 
        <Grid item my={1} xs={10} md={12}> 
          <Typography color={'#FFFFFF'} sx={{fontWeight: theme.typography.h6.fontWeight, lineHeight: theme.typography.h2.lineHeight}} variant='h3'>“Your AI tool is *the bee's knees*. I gave it one detailed prompt and it generated the complete survey. Perfectly.”</Typography>
        </Grid>
        <Grid item my={1} xs={10} md={12}>
          <Typography color={'#FFFFFF'} sx={{fontWeight: theme.typography.h6.fontWeight, lineHeight: theme.typography.h2.lineHeight}} variant='h4'>Mohammed, beta customer</Typography>
        </Grid>
      </Grid>
      <Grid style={{display:'none'}} container mt={1} spacing={2} className={classes.section2}> 
        <Grid sx={{textAlign: 'center'}} item my={1} xs={12} md={12}>
          <Typography sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h1'>Join more than 17 million active users worldwide</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4}> 
            <Grid item mx={1} xs={4} md={1}> 
              <img
                className={classes.logo}
                src="../../static/logos/1.png"
                alt='VS'
                loading="lazy"
              />
            </Grid>
            <Grid item mx={1} xs={4} md={1}> 
            <img
              className={classes.logo}
              src="../../static/logos/3.png"
              alt='VS'
              loading="lazy"
            />
            </Grid>
            <Grid item mx={1} xs={4} md={1}> 
            <img
              className={classes.logo}
              src="../../static/logos/12.png"
              alt='VS'
              loading="lazy"
            />
            </Grid>
            <Grid item mx={1} xs={4} md={1}> 
            <img
              className={classes.logo}
              src="../../static/logos/5.png"
              alt='VS'
              loading="lazy"
            />
            </Grid>
            <Grid item mx={1} xs={4} md={1}> 
            <img
              className={classes.logo}
              src="../../static/logos/6.png"
              alt='VS'
              loading="lazy"
            />
            </Grid>
            <Grid item mx={1} xs={4} md={1}> 
            <img
              className={classes.logo}
              src="../../static/logos/7.png"
              alt='VS'
              loading="lazy"
            />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={2} className={classes.section2}> 
        <Grid container py={3} sx={{ flexDirection: 'row', alignItems: 'center', textAlign: 'center', borderRadius: '4px', boxShadow: 'rgba(51, 62, 72, 0.1) 0px 8px 16px 0px'}}>
          <Grid item xs={12} md={1}>
            <img
              width='70px'
              src="../../favicon.ico"
              alt='VS'
              loading="lazy"/>
          </Grid>
          <Grid item xs={12} md={9} sx={{textAlign: { xs: "center", md: "left"}}}>
            <Grid container> 
              <Grid item my={1} xs={12} md={12}> 
                <Typography sx={{fontWeight: theme.typography.h6.fontWeight, lineHeight: theme.typography.h2.lineHeight}} variant='h2'>Get the latest AI trends and insights</Typography>
              </Grid>
              <Grid item my={1} xs={12} md={12}>
                <Typography sx={{fontWeight: theme.typography.h6.fontWeight, lineHeight: theme.typography.h2.lineHeight}} variant='h4'>Stay up to date with original research from Crux on how artificial intelligence is shaking things up in your industry or for your role.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
          <Button
            style={{ textTransform: 'none', borderColor: 'primary', color: 'primary', backgroundColor: '#FFFFFF'}}
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/`}
            variant="outlined"
          >
            <Typography color={'primary'} variant="h5">
            {'Learn more'}
            </Typography>
          </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={3} spacing={2} className={classes.section2} textAlign='center'> 
        <Grid item xs={8}>
          <Typography gutterBottom sx={{fontWeight: '700', letterSpacing: 'normal'}} variant='h3'>Frequently asked questions</Typography>
        </Grid>
        <Grid item xs={6}>
          <Accordion sx={{boxShadow: 'none'}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography gutterBottom sx={{fontWeight: '600', letterSpacing: 'normal'}} variant='h4'>What are Crux guiding principles for AI?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: 'left', fontWeight: '500', letterSpacing: 'normal'}} variant='subtitle1'>
              All of the AI and machine learning we use in our products is designed to empower our customers to get fast answers to their critical questions, taking the guesswork out of survey creation and analysis. 
              We believe that transparency builds trust; that’s why we’re open about our use of AI and machine learning, and why we’re committed to giving our customers opportunities to share their feedback about these capabilities. We know that AI is constantly evolving and we strive to help our customers keep up and get ahead with the help of both our products and research.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion sx={{boxShadow: 'none'}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography gutterBottom sx={{fontWeight: '600', letterSpacing: 'normal'}} variant='h4'>How can I get started with Crux AI features?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: 'left', fontWeight: '500', letterSpacing: 'normal'}} variant='subtitle1'>
              Crux AI features are available in our product throughout the survey creation, fielding and analysis steps. 
              Feature availability can differ by region and by plan.</Typography>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion sx={{boxShadow: 'none'}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography gutterBottom sx={{fontWeight: '600', letterSpacing: 'normal'}} variant='h4'>Will Crux AI get smarter over time?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: 'left', fontWeight: '500', letterSpacing: 'normal'}} variant='subtitle1'>
              Yes. We’re constantly improving our AI and machine learning models and approaches by leveraging the latest techniques available. This helps us ensure the best possible experience for our customers. 
              In addition, a lot of our features that leverage AI and machine learning include feedback loops for our customers to provide corrections. This data is then fed back into our AI and machine learning features and allows us to constantly improve our predictions and recommendations.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion sx={{boxShadow: 'none'}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography gutterBottom sx={{textAlign: 'left', fontWeight: '600', letterSpacing: 'normal'}} variant='h4'>How does Crux ensure the privacy and security of my data?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: 'left', fontWeight: '500', letterSpacing: 'normal'}} variant='subtitle1'>
              To understand how we use personal information, and learn about our data policies and your rights, please read our privacy basics page. 
              To understand our security practices and responsibilities, please read our security statement.</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={6} className={classes.section5}> 
        <Grid item xs={6} md={6}>
          <Typography gutterBottom color={'#FFFFFF'} sx={{fontWeight: '500', fontSize: '48px', lineHeight: '55px', letterSpacing: 'normal'}} variant='h1'>See what Crux AI can create for you</Typography>
          <Button
            style={{ marginTop: theme.spacing(5), textTransform: 'none', borderColor: '#FFFFFF', color: '#FFFFFF', backgroundColor: '#FFFFFF'}}
            component={RouterLink}
            size='large'
            to={!!currentUser ? `${process.env.PUBLIC_URL}/app/create` : "https://app.crux360.ai/session/signup"}
            variant="outlined"
          >
            <Typography color={'primary'} variant="h5">
            { !!currentUser ? 'Create Survey' : 'Sign Up Free'}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Page >
  );
};
export default HomeView;
