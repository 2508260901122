import React from 'react';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import { SurveyContextProvider } from "./context";
import Home from './components/Home';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  }
}));

const SurveysView = () => {
  const classes = useStyles();

  return (
    <Page
      title="Crux"
      className={classes.root}
    >
      <SurveyContextProvider>
        <Home />
      </SurveyContextProvider>
    </Page >
  );
};
export default SurveysView;
