import React from 'react';
import {
  CardHeader,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RibbonContainer,  Ribbon } from "react-ribbons";
import theme from '../../../theme';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import AuthService from '../../../services/auth.service';

const StyledCard = styled((props) => {
  const { color, team, ...other } = props;
  return <Card {...other} />;
})(({ theme, color, team }) => ({
  border: '1px solid rgb(208, 210, 211)',
  boxShadow: 'none',
  height: '100%',
  textAlign: 'center',
  "&:hover": {
    borderColor: color
  },
  [theme.breakpoints.up('md')]: {
    height: team ? '147vw' : '85vw',
  },
}));

const useStyles = makeStyles(() => ({
  cardHeader: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      height: '9vw',
    },
  },
}));

const Pricing = ({data, label}) => {
  const classes = useStyles();
  const currentUser = AuthService.getCurrentUser();

  return (
    <Grid container spacing={1} justifyContent={'center'}>
      {data.map((d, i) => {
        return(
        <Grid key={i} item xs={12} md={d.name === 'Team' ? 3 : 4} sm={d.name === 'Team' ? 4 : 4}>
          <RibbonContainer>
            {!!d.ribbon && <Ribbon
              side="right"
              type="corner"
              size="normal"
              color='white'
              fontFamily={theme.typography.h1.fontFamily}
              backgroundColor={d.color.toString()}
              withStripes={false}
            >
              {d.ribbon}
            </Ribbon>}
            <StyledCard team={d.name === 'Team' ? true : false} color={d.color.toString()}>
              <CardHeader className={classes.cardHeader} title={
                <Box style={{margin: '1px'}}>
                  <Typography gutterBottom variant='h2'>{d.title}</Typography>
                  {!!d.subTitle && <Typography><span style={{ fontSize: "24px", }}>{d.subTitle.split('/')[0]}</span> {'/' + d.subTitle.split('/').slice(1).join('/')}</Typography>}
                </Box>
              } subheader={d.desc}></CardHeader>
              <CardContent>
              <Button
                sx={{backgroundColor: d.color.toString(), "&:hover": { backgroundColor: d.color.toString() }, margin: theme.spacing(2,0)}}
                component={RouterLink}
                to={d.title !== 'Enterprise' ?  "https://app.crux360.ai/session/signup" : `${process.env.PUBLIC_URL}/pricing#contactus`}
                fullWidth
                size="large"
                variant="contained"
              >
                {d.title !== 'Enterprise' ? label : 'Contact Us'}
              </Button>
              <List sx={{ color: 'GrayText', textAlign: 'left', marginTop: '10px', marginLeft: '20px', listStyleType: 'disc' }}>
                {d.list.map((r, idx) => {
                  return(<ListItem key={idx} sx={{ display: 'list-item' }}> 
                  <Typography color='GrayText' variant='body1'>{r}</Typography> 
                </ListItem>)
                })}
              </List>
              </CardContent>
            </StyledCard>
            <Button
                sx={{backgroundColor: d.color.toString(), "&:hover": { backgroundColor: d.color.toString() }, margin: theme.spacing(2,0)}}
                component={RouterLink}
                to={d.title !== 'Enterprise' ? "https://app.crux360.ai/session/signup" : `${process.env.PUBLIC_URL}/pricing#contactus`}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {d.title !== 'Enterprise' ? label : 'Contact Us'}
              </Button>
          </RibbonContainer>
        </Grid>)
      })}
    </Grid>
  );
};
export default Pricing;
