import React  from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  Alert,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  List,
  ListItem
} from '@mui/material';
import Page from '../../../components/Page';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  card: {
    border: 'none',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
    textAlign: 'left'
  },
  content: {
    padding: theme.spacing(0, 2)
  }
}));

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#221bff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#0000c6',
    },
});
  
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

const ContactUs = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={'Crux CX - Contact Us'}
    >
      <Box alignItems={'center'} display='flex' flexDirection='column' justifyContent = 'space-evenly'>
        <Grid container direction="row" justifyContent="left">
            <Grid item xs={12} md={6}>
                <Typography gutterBottom variant='h2'>Contact us today</Typography>
                <Typography gutterBottom variant='body1'>Learn more about Crux Enterprise and schedule a demo.</Typography>
                <List sx={{ color: 'GrayText', textAlign: 'left', marginTop: '10px', marginLeft: '20px', listStyleType: 'disc' }}>
                    <ListItem sx={{ display: 'list-item' }}> 
                        <Typography variant='subtitle1'>Manage multiple users and gain visibility into all survey data collected across your organization with admin controls and dashboards.</Typography> 
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}> 
                        <Typography variant='subtitle1'>Ensure confidential data is protected with enhanced security including encryption, SSO, and features that help you remain compliant with HIPAA and GDPR.</Typography> 
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}> 
                        <Typography variant='subtitle1'>Make feedback automated and actionable by connecting to key business systems using APIs and powerful integrations, including Salesforce, Marketo, Tableau, and more.</Typography> 
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} md={6}>
                <Container maxWidth="sm">
                    <Formik
                    initialValues={{
                        username: '',
                        terms: false,
                        promotions: false
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().max(255).required(`${('username_isrequired')}`),
                    })}
                    >
                    {({
                        errors,
                        status,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box pt={3}>
                        <Card className={classes.card}>
                            <CardContent style={{backgroundColor: '#F7F8FA'}}>
                                <Box className={classes.content}>
                                    {status && status.error &&
                                        <Box my={2}>
                                        <Alert variant="outlined" severity="error">
                                            {status.error}
                                        </Alert>
                                        </Box>
                                    }
                                    <Typography variant='h6'>First name</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                    <Typography variant='h6'>Last name</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                    <Typography variant='h6'>Business email</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                    <Typography variant='h6'>Job title</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                    <Typography variant='h6'>Company</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                    <Typography variant='h6'>Phone number</Typography>
                                    <TextField
                                        fullWidth
                                        style={{marginTop: '5px'}}
                                        margin="normal"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.username}
                                        variant="outlined"
                                        color='text'
                                    />
                                <FormGroup>
                                    <FormControlLabel sx={{marginBottom: '4px', alignItems:'flex-start'}} onChange={handleChange} name="terms" value={values.terms} control={<BpCheckbox />} label={
                                    <><Typography style={{marginBottom: '9px'}} variant='body2'>
                                    I wish to receive other information about Crux CX, like the news and updates described here. You can unsubscribe at any time.</Typography> 
                                    <Typography variant='body2'>By clicking 'Contact sales', I am requesting that someone contact me about my inquiry and confirming I have read the Crux Privacy Notice.</Typography></>
                                    } />
                                </FormGroup>
                                <Box my={2}>
                                    <Button
                                    style={{ textTransform: 'none', borderColor: 'white', color: 'white'}}
                                    color='primary'
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    >
                                    {('Contact sales')}
                                    </Button>
                                </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        </Box>
                    </form>
                    )}
                    </Formik>
                </Container>
            </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default ContactUs;
