import React, { useEffect, useContext } from 'react';
import {
  Typography,
  Grid,
  Button,
  Container,
} from '@mui/material';
import Page from '../../../components/Page';
import { makeStyles } from '@mui/styles';
import { CheckOutContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh !important',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.white,
  },
  order: {
    backgroundColor: '#F7F8FA',
    padding: theme.spacing(1, 3),
    borderBottom: '1px solid #EDEEEE'
  },
  total: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    backgroundColor: '#F7F8FA',
    justifyContent: 'space-between'
  },
  orderDetails: {
    padding: theme.spacing(2, 3),
    border: '1px solid #EDEEEE'
  }
}));

const Review = () => {
  const classes = useStyles();
  const checkOutContext = useContext(CheckOutContext);
  const { plan, billingUser, detailsList, handleTabChange } = checkOutContext;
  const [businessView, setBusinessView] = React.useState(false);
  const [values, setValues] = React.useState({});

  const cost = (val) => {
    if (!!val) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return 0;
  }

  useEffect(() => {
    if (plan && plan.plan === 'Business plans (multiple users)') {
      setBusinessView(true)
    } else {
      setBusinessView(false)
    }
    if (plan && plan.title) {
      let d = detailsList.find((aa) => aa.title === plan.title)
      setValues(d);
    }

    window.Moyasar.init({
      element: '.mysr-form',
      // Amount in the smallest currency unit.
      // For example:
      // 10 SAR = 10 * 100 Halalas
      // 10 KWD = 10 * 1000 Fils
      // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
      amount: billingUser.price*100,
      currency: "SAR",
      description: "Subcription Order #1",
      publishable_api_key: "pk_test_eLUwVUWBownQV6cmDsr825hcK5bMj8fiBS59sbZj",
      callback_url: "https://moyasar.com/thanks",
      methods: ["creditcard"],
      // methods: ["creditcard", "stcpay", "applepay"],
      // apple_pay: {
      //   country: "SA",
      //   label: "Awesome Cookie Store",
      //   validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate"
      // }
    });
  }, [plan, detailsList, billingUser]);

  return (
    <Page
      className={classes.root}
      title={'Crux CX - Contact Us'}
    >
      <Container maxWidth="xl" >
        <Grid container display='flex' flexDirection='row' justifyContent='center' alignContent='center' spacing={4}>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant='h3'>Payment</Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{maxWidth: '100%'}} className="mysr-form" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container rowSpacing={2}>
              <Grid item className={classes.order} style={{ marginBottom: '10px' }} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant='h3'>Billing address</Typography>
                    <Button variant='outlined' color='secondary' onClick={(e) => handleTabChange(e, 2)} >Edit</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant='h5'>{billingUser.firstName + ' ' + billingUser.lastName}</Typography>
                    <Typography gutterBottom variant='body1'>{billingUser.email}</Typography>
                    <Typography gutterBottom variant='body1'>{!!billingUser.country && billingUser.country.label ? billingUser.country.label : ''}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.order} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant='h3'>Order summary</Typography>
                    <Button variant='outlined' color='secondary' onClick={(e) => handleTabChange(e, 0)} >Edit</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant='h5'>Plan: {values.title}</Typography>
                    <Typography gutterBottom variant='body1'>Billing: {values.billing}</Typography>
                    {!businessView && <Typography gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }} variant='body1'>SAR {values.price} x 12 months <span>SAR {cost(values.price * 12)}</span> </Typography>}
                  </Grid>
                  {!!businessView && !!plan.fullAccessCount && <Grid item xs={12}>
                    <Typography gutterBottom variant='h5'>Full Access: {plan.fullAccessCount} users</Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }} variant='body1'>{plan.fullAccessCount} x SAR {values.price} x 12 months <span>SAR {cost(plan.fullAccessCount * values.price * 12)}</span> </Typography>
                  </Grid>}
                  {!!businessView && !!plan.analystsCount && plan.analystsCount > 1 && <Grid item xs={12}>
                    <Typography gutterBottom variant='h5'>Analysts: {plan.analystsCount}</Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }} variant='body1'>{plan.analystsCount} x SAR {values.analystsPrice} x 12 months <span>SAR {cost(plan.analystsCount * values.analystsPrice * 12)}</span> </Typography>
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item className={classes.total} xs={12}>
                <Typography style={{ display: 'flex' }} gutterBottom variant='h5'>Total<Typography style={{ paddingLeft: '2px' }} variant='body1'> (VAT included)</Typography></Typography>
                <Typography gutterBottom variant='body1'><span>SAR {cost(billingUser.price)}</span> </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Review;
